import { useState } from 'react';
import { Card, Typography, IconButton, Box } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { BarChart } from '@mui/x-charts/BarChart';

const VesselsChart = ({ vesselsData, vesselsXLabels, height }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const MAX_ITEMS = 10;

    const handleNext = () => {
        if (currentIndex + MAX_ITEMS < vesselsData.length) {
            setCurrentIndex(currentIndex + MAX_ITEMS);
        }
    };

    const handlePrev = () => {
        if (currentIndex - MAX_ITEMS >= 0) {
            setCurrentIndex(currentIndex - MAX_ITEMS);
        }
    };

    const currentDataSlice = vesselsData.slice(currentIndex, currentIndex + MAX_ITEMS);
    const currentLabelsSlice = vesselsXLabels.slice(currentIndex, currentIndex + MAX_ITEMS);

    return (
        <Card
            sx={{
                p: 1,
                flex: 3,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 350,
                minHeight: 300,
                boxSizing: 'border-box',
                overflowX: 'auto',
            }}
        >
            <Typography align="center" gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                Alertas por embarcação
            </Typography>
            <Box sx={{ overflowX: 'auto', flex: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
                        <ArrowBack />
                    </IconButton>
                    <Box sx={{ flex: 1 }}>
                        {currentDataSlice.length > 0 ? (
                            <BarChart
                                height={height}
                                series={[{ data: currentDataSlice, id: 'vessels' }]}
                                barLabel={(item, context) => {
                                    return context.bar.height < 30 ? null : item.value?.toString();
                                }}
                                margin={{ bottom: 100 }}
                                sx={{ p: 3, 
                                    m: 0,
                                    '& .MuiBarLabel-root': {
                                        fontWeight: 'bolder', // Make labels bold
                                    }
                                }}
                                xAxis={[{
                                    scaleType: 'band',
                                    data: currentLabelsSlice,
                                    tickLabelStyle: { fontSize: 12, angle: 25, textAnchor: 'start' },
                                }]}
                            />
                        ) : (
                            <Typography variant="body2" color="textSecondary" align="center">
                                Sem dados para exibir
                            </Typography>
                        )}
                    </Box>
                    <IconButton onClick={handleNext} disabled={currentIndex + MAX_ITEMS >= vesselsData.length}>
                        <ArrowForward />
                    </IconButton>
                </Box>
            </Box>
        </Card>
    );
};

export default VesselsChart;
