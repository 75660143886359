import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { Paper, Box, Typography, Accordion, AccordionDetails, IconButton, Button, Snackbar, Alert, Modal, TextField, Stack, Badge, Backdrop, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, ptBR } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { PieChart } from '@mui/x-charts/PieChart';

import OnlineAvatar from './OnlineAvatar';
import UnavailableAvatar from './UnavailableAvatar';
import OfflineAvatar from './OfflineAvatar';

import './KeepAlive.css';

dayjs.locale('pt-br');

const StatusPage = () => {
    const navigate = useNavigate();
    const requestAbortController = React.useRef(null);
    const initialValue = dayjs(new Date());
    const [expanded, setExpanded] = useState(null);
    const [equipments, setEquipments] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    const baseUrl = process.env.REACT_APP_LISTEN_ADDRESS;
    const [equipmentsByID, setEquipmentsByID] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);


    const createHandleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const createHandleOnEntered = (panel) => () => {
        const summaryNode = document.getElementById(`${panel}bh-header`);
        summaryNode.scrollIntoView({ block: "start", behavior: "smooth" });
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getThisKey = async (event) => {
        const selectedKey = event.target.getAttribute('data-key');
        fetchDates(selectedKey);
        handleOpen();
    };


    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        minHeight: '35px !important', // Set directly on root
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
            margin: '0px!important',
            minHeight: '0px',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
            margin: '0px!important',
            minHeight: '0px!important'
        },
        ...theme.applyStyles('dark', {
            backgroundColor: 'rgba(255, 255, 255, .05)',
        }),
    }));

    useEffect(() => {
        const fetchData = async () => {
            if (!user.token) return;
            
            try {
                setLoading(true);
                const response = await fetch(`${baseUrl}/equipment`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem("user");
                        navigate("/login", { replace: true });
                        return;
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }

                const data = await response.json();
                setEquipments(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [baseUrl, user.token, navigate]);

    const getDifferenceInDays = (lastKeepalive) => {
        const currentDate = new Date();
        const gmtOffset = -3;
        const lastKeepaliveDate = lastKeepalive ? new Date(new Date(lastKeepalive).getTime() + (gmtOffset) * 60 * 60 * 1000) : null;
        const differenceInDays = lastKeepaliveDate ? Math.floor((currentDate - lastKeepaliveDate) / (24 * 60 * 60 * 1000)) : null;
        return differenceInDays;
    };


    const getEquipmentStatus = (equipment) => {
        const differenceInDays = getDifferenceInDays(equipment.last_keepalive_ts_received);
        if (differenceInDays > 7) return 'offline';
        if (equipment.last_keepalive_ts_received) return 'online';
        return 'unavailable';
    };

    const calculateEquipmentCounts = () => {
        const counts = { online: 0, offline: 0, unavailable: 0 };

        equipments.forEach((equipment) => {
            const status = getEquipmentStatus(equipment);
            counts[status]++;
        });
        return counts;
    };

    const { online, offline, unavailable } = calculateEquipmentCounts();
    const data = [
        { label: 'Online', value: online, color: 'green' },
        { label: 'Offline', value: offline, color: 'darkred' },
        { label: 'Indisponível', value: unavailable, color: 'grey' },
    ];

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        const gmtOffset = -3;
        const adjustedDate = new Date(date.getTime() + (gmtOffset) * 60 * 60 * 1000);
        return `${String(adjustedDate.getDate()).padStart(2, '0')}/${String(adjustedDate.getMonth() + 1).padStart(2, '0')}/${adjustedDate.getFullYear()}`;
    };

    const ServerDay = (props) => {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
        const isSelected = !outsideCurrentMonth && highlightedDays.includes(day.format('YYYY-MM-DD'));
        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={isSelected ? '🟢' : ''}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    };

    const FetchDates = async (date, { signal }) => {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                const currentDate = dayjs(date);
                const currentMonth = currentDate.format('YYYY-MM');
                let daysToHighlight = [];
                if (equipmentsByID[0] && Array.isArray(equipmentsByID[0])) {
                    daysToHighlight = equipmentsByID[0].filter(day => day.startsWith(currentMonth));
                }
                resolve({ daysToHighlight });
            }, 0);

            signal.onabort = () => {
                clearTimeout(timeout);
                reject(new DOMException('aborted', 'AbortError'));
            };
        });
    };

    const DateCalendarServerRequest = () => {
        const [isLoading, setIsLoading] = React.useState(false);
        const [highlightedDays, setHighlightedDays] = React.useState([]);

        const fetchHighlightedDays = (date) => {
            const controller = new AbortController();
            FetchDates(date, { signal: controller.signal })
                .then(({ daysToHighlight }) => {
                    setHighlightedDays(daysToHighlight);
                    setIsLoading(false);
                })
                .catch((error) => {
                    if (error.name !== 'AbortError') {
                        throw error;
                    }
                });

            requestAbortController.current = controller;
        };

        React.useEffect(() => {
            fetchHighlightedDays(initialValue);
            return () => requestAbortController.current?.abort();
        }, []);

        const handleMonthChange = (date) => {
            if (requestAbortController.current) {
                requestAbortController.current.abort();
            }
            setIsLoading(true);
            setHighlightedDays([]);
            fetchHighlightedDays(date);
        };

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DateCalendar
                    defaultValue={initialValue}
                    loading={isLoading}
                    onMonthChange={handleMonthChange}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{ day: ServerDay }}
                    slotProps={{ day: { highlightedDays } }}
                />
            </LocalizationProvider>
        );
    };

    const renderAccordions = (status) => {
        const statusLabels = {
            online: { label: 'Online', color: '#4caf50' },
            offline: { label: 'Offline', color: '#f44336' },
            unavailable: { label: 'Indisponível', color: '#9e9e9e' },
        };

        const filteredEquipments = React.useMemo(
            () => equipments.filter((equipment) => getEquipmentStatus(equipment) === status),
            [status, equipments]
        );

        const { label, color } = statusLabels[status];
        const description =
            label === 'Online'
                ? 'Ativas'
                : label === 'Offline'
                    ? 'Inativas'
                    : 'Fora de Serviço';

        const renderHeader = filteredEquipments.length > 0 && (
            <Typography>
                <span style={{ fontSize: '1.1em', fontWeight: 'bold', color }}>
                    {`${label} (${filteredEquipments.length})`}
                </span>
                {` - Embarcações ${description}`}
            </Typography>
        );


        const renderAccordionDetails = (equipment) => (
            <AccordionDetails sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography>Serial: {equipment.serial_number}</Typography>
                    {equipment.last_keepalive_ts_received && (
                        <Typography>
                            Última vez online: {formatDate(equipment.last_keepalive_ts_received)}
                        </Typography>
                    )}

                    <Stack direction="row" spacing={2} sx={{ flexGrow: 1, mt: 2 }} alignItems="center">
                        <TextField
                            label="Observações"
                            variant="outlined"
                            fullWidth
                            placeholder="Adicione observações aqui"
                            defaultValue={equipment.notes || ""}
                            inputProps={{ maxLength: 250 }}
                            disabled={!(user.role === 'Lotus' || user.role === 'Admin')}
                            id={`notes-input-${equipment.id}`}
                            multiline
                            rows={2}
                            sx={{
                                '& .MuiInputBase-root': {
                                    maxHeight: '200px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '10px',
                                }
                            }}
                        />

                        <IconButton
                            color="primary"
                            onClick={() => handleConfirmClick(equipment.id)}
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                width: 40,
                                height: 40,
                                borderRadius: 1,
                                '&:hover': { bgcolor: 'primary.main' }
                            }}
                            disabled={!(user.role === 'Lotus' || user.role === 'Admin')}
                        >
                            <CheckIcon />
                        </IconButton>
                    </Stack>
                </Box>
            </AccordionDetails>
        );

        return (
            <>
                {renderHeader}

                <Paper className="acordion">
                    {filteredEquipments.map((equipment) => (
                        <Accordion
                            key={equipment.id}
                            expanded={expanded === `panel${equipment.id}`}
                            onChange={createHandleChange(`panel${equipment.id}`)}
                            sx={{
                                boxShadow: 'none',  // Remove the box shadow
                            }}
                            slotProps={{
                                transition: {
                                    onEntered: createHandleOnEntered(`panel${equipment.id}`),
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${equipment.id}bh-content`}
                                id={`panel${equipment.id}bh-header`}

                            >
                                {renderAvatar(equipment.last_keepalive_ts_received)}
                                <Typography sx={{ width: '33%', flexShrink: 0, ml: 2 }}>
                                    {equipment.ship_owner && equipment.display_name
                                        ? `${equipment.ship_owner} - ${equipment.display_name}`
                                        : equipment.identifier}
                                </Typography>
                            </AccordionSummary>
                            <Button data-key={equipment.id} onClick={getThisKey} sx={{ mx: 1 }}>
                                Ver Calendário
                            </Button>

                            {renderAccordionDetails(equipment)}  {/* Extracted render logic */}
                        </Accordion>
                    ))}
                </Paper>

            </>
        );
    };


    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleConfirmClick = async (equipmentId) => {

        try {
            const notes = document.getElementById(`notes-input-${equipmentId}`).value;

            if (!notes || notes.length > 255) {
                setSnackbarMessage("As observações devem ter entre 1-255 caracteres.");
                setSnackbarSeverity("warning");
                setOpenSnackbar(true);
                return;
            }

            const response = await fetch(`${baseUrl}/equipment/notes/?equipment_id=${equipmentId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify({ notes: notes })
            });



            if (!response.ok) {
                throw new Error('Falha ao atualizar as observações');
            }

            setSnackbarMessage("Observações atualizadas com sucesso!");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);

        } catch (error) {
            setSnackbarMessage("Falha ao atualizar as observações. Tente novamente.");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        }
    };

    const renderAvatar = (lastKeepalive) => {
        const differenceInDays = getDifferenceInDays(lastKeepalive);
        if (differenceInDays > 7) {
            return <OfflineAvatar />;
        } else if (lastKeepalive) {
            return <OnlineAvatar />;
        } else {
            return <UnavailableAvatar />;
        }
    };

    const fetchDates = async (selectedKey) => {
        if (!selectedKey) {
            console.error('Invalid button key');
            return;
        }
        const controller = new AbortController();
        try {
            const response = await fetch(`${baseUrl}/keepalive-log/by-equipment/${selectedKey}/dates`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            if (!response.ok) {
                setEquipmentsByID([]);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setEquipmentsByID(Object.values(data));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        requestAbortController.current = controller;
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                sx={{
                    marginTop: '64px',
                }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    variant="filled"
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>


            <Paper variant="outlined" sx={{ mx: 2, px: 4, py: 1, my: 1, pb: 2 }}>
                <Box sx={{ display: 'flex', mt: 2, flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'space-around' }}>
                    <Box sx={{ minWidth: '300px', maxWidth: 1000, display: 'flex', flexDirection: 'column', flex: 1, gap: '10px' }}>
                        <Typography gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                            Informações das embarcações
                        </Typography>

                        {renderAccordions('online')}
                        {renderAccordions('offline')}
                        {renderAccordions('unavailable')}
                    </Box>

                    <Modal
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 0,
                            p: 4,
                        }}>
                            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                                Keep Alive
                            </Typography>
                            <Typography sx={{ p: 1, m: 1 }}>
                                Verifique as datas em que a embarcação esteve online 🟢
                            </Typography>

                            <DateCalendarServerRequest />
                        </Box>
                    </Modal>

                    <Box sx={{ maxWidth: 500, flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PieChart
                            series={[{ data }]}
                            height={500}
                            padding={10}
                            margin={{ right: 150 }}
                        />
                    </Box>
                </Box>

                <Backdrop
                    sx={(theme) => ({
                        color: '#fff',
                        zIndex: theme.zIndex.drawer + 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    })}
                    open={loading}
                    onClick={(e) => e.stopPropagation()}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Paper>
        </>
    );
};

export default StatusPage;
