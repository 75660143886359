import React, { useState, useEffect } from 'react';
import {Paper,Typography,Box} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import { useTheme, styled, darken, lighten } from '@mui/material/styles';
import ScrollbarWrapper from '../../componentes/themes/scrollbar';
import { useNavigate } from 'react-router-dom';
import Filters from '../../componentes/Filters';
import dayjs from 'dayjs';
import EventModal from './EventModal';

function formatDate(datetime) {
    // Create a new Date object from the input datetime
    const date = new Date(datetime);

    // Adjust for GMT-3
    const gmtOffset = -3; // GMT-3 offset in hours
    const adjustedDate = new Date(date.getTime() + (gmtOffset) * 60 * 60 * 1000);

    const day = String(adjustedDate.getDate()).padStart(2, '0');
    const month = String(adjustedDate.getMonth() + 1).padStart(2, '0');
    const year = adjustedDate.getFullYear();
    const hours = String(adjustedDate.getHours()).padStart(2, '0');
    const minutes = String(adjustedDate.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const columns = [
    // {
    //     field: "thumbnail",
    //     headerName: "Imagem",
    //     width: 200,
    //     renderCell: (params) => {
    //         return (
    //             <Box
    //                 sx={{
    //                     display: 'flex',
    //                     justifyContent: 'center',
    //                     alignItems: 'center',
    //                     height: '100%'
    //                 }}
    //             >
    //                 <img src={"https://c4.wallpaperflare.com/wallpaper/694/597/166/platform-offshore-metso-building-wallpaper-preview.jpg"} alt="Thumbnail" style={{ width: '100%', height: 85 }} />
    //             </Box>
    //         );
    //     }
    // },
    { field: 'ship_owner', headerName: 'Armador', type: 'string', flex: 1, sortable: false },
    { field: 'ship_name', headerName: 'Embarcação', type: 'string', flex: 1, sortable: false },
    { field: 'events', headerName: 'Desvios', type: 'string', flex: 1, sortable: false, valueGetter: (params) => renderEvents(params.row.events) },
    {
        field: 'datetime',
        headerName: 'Data e Hora',
        type: 'string',
        valueGetter: ({ value }) => formatDate(value),
        flex: 1,
        sortable: false,
    },
    {
        field: 'severity',
        headerName: 'Severidade',
        type: 'singleSelect',
        valueOptions: ['Baixo', 'Normal', 'Médio', 'Crítico'],
        flex: 1,
        sortable: false,
    },
];

const renderEvents = (events) => {
    return events.map(event => event.label).join(', ');
};

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--Open': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Filled': {
        backgroundColor: getBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--PartiallyFilled': {
        backgroundColor: getBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Crítico': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36-.144-.573-.221-1.172-.221-1.789V75.1h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.972 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>Nenhum evento encontrado</Box>
        </StyledGridOverlay>
    );
}

const alerts = [
    { label: 'Pessoa caída', value: 'falling' },
    { label: 'Sem macacão', value: 'no-coverall' },
    { label: 'Sem colete', value: 'no-jacket' },
    { label: 'Sem capacete', value: 'no-helmet' },
    { label: 'Sem botas', value: 'no-boots' },
    { label: 'Sem luvas', value: 'no-gloves' },
    { label: 'Área proibida', value: 'intrusion' },
    //{ label: 'Sem protetor auricular', value: 'no-ear-protector' },
    //{ label: 'Sem óculos', value: 'no-glasses' },
];

const alertMapping = alerts.reduce((map, alert) => {
    map[alert.value] = alert.label;
    return map;
}, {});

const EventPage = () => {

    const [openModal, setOpenModal] = useState(false);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
    const [rowCount, setRowCount] = useState(0);
    
    const [selectedRow, setSelectedRow] = useState(null);
    
    const [selectedFilters, setSelectedFilters] = useState({
        equipment_identifiers: [],
        ship_owners: [],
        describe_info: [],
        severities: [],
        start_date: dayjs().subtract(7, 'day'),
        end_date: dayjs(),
    });
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const baseUrl = process.env.REACT_APP_LISTEN_ADDRESS;
    const [loading, setLoading] = useState(false);


    useEffect(() => {


        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                const filterParams = new URLSearchParams();
                Object.entries(selectedFilters).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach(val => filterParams.append(key, val));
                    } else if (value) {
                        filterParams.append(key, key.includes('date') ? dayjs(value).format() : value);
                    }
                });

                const response = await fetch(
                    `${baseUrl}/sync-event?page=${paginationModel.page}&page_size=${paginationModel.pageSize}&${filterParams}`,
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );

                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user');
                        navigate('/login', { replace: true });
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }

                const responseData = await response.json();
                setRowCount(responseData.total_count);
                setRows(
                    responseData.data.map(event => ({
                        id: event.id,
                        ship_name: event.equipment_display_name || event.equipment_identifier,
                        ship_owner: event.equipment_ship_owner || 'Desconhecido',
                        events: event.event_objects.map(obj => ({
                            label: alertMapping[obj.describe_info] || obj.describe_info,
                            bbox: obj
                        })),
                        datetime: event.occur_time,
                        severity: event.severity,
                    }))
                );
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        
        if (user?.token) {
            fetchData();
        }
    }, [user?.token, baseUrl, paginationModel, selectedFilters, navigate]);


    const handleFilterChange = (filterType, selectedOptions) => {
        setSelectedFilters(prevFilters => ({ ...prevFilters, [filterType]: selectedOptions }));
    };

    const handleRowClick = (params) => {
        setSelectedRow(params.row);
        setOpenModal(true);
    };


    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenModal(false);
        setSelectedRow(false);
    }

 
    const theme = useTheme();
    const fontSize = window.innerWidth < theme.breakpoints.values.sm ? '10px' : window.innerWidth < theme.breakpoints.values.md ? '12px' : '14px';
    const dataGridStyle = { fontSize, minWidth: 600 };


    return (
        <Paper sx={{ m: 2, p: 4, minHeight: '500px' }}>
            <Typography gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                Registro de eventos
            </Typography>
            <Filters onFilterChange={handleFilterChange} />
            <Box sx={{ display: 'flex', gap: 3, mt: 1 }}>
                <ScrollbarWrapper>
                    <StyledDataGrid
                        rowCount={rowCount}
                        style={dataGridStyle}
                        rows={rows}
                        columns={columns}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        disableColumnMenu
                        autoHeight
                        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                        getRowHeight={() => 100}
                        getRowClassName={(params) => `super-app-theme--${params.row.severity}`}
                        onRowClick={handleRowClick}
                    />
                </ScrollbarWrapper>
            </Box>


            <EventModal
                open={openModal}
                selectedRow={selectedRow}
                onClose={handleCloseModal}
            >

            </EventModal>

            <Backdrop
                sx={(theme) => ({
                    color: '#fff',
                    zIndex: theme.zIndex.drawer + 999,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                })}
                open={loading} // Use loading state to control the backdrop
                onClick={(e) => e.stopPropagation()} // Prevent click events from passing through
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Paper>

        
    );
};

export default EventPage;
