import HomePage from "../pages/Home/HomePage";
import EventPage from "../pages/Event/EventPage"
import StatusPage from "../pages/Status/StatusPage"
import { userRoles as ur } from "../data/userRole";
import ResponsiveAppBar from "../componentes/Navbar/Navbar";
import VesselsPage from "../pages/Vessels/vessels";
export const general_routes = [
    {
        path: "/home",
        ele: <>
            <ResponsiveAppBar />
            <HomePage />
        </>,
        availability: [ur.admin, ur.lotus, ur.privado, ur.petrobras, ur.bravante, ur.wsut, ur.starnav, ur.cbo, ur.ambipar, ur.asso]
    },
    {
        path: "/events",
        ele:
            <>
                <ResponsiveAppBar />
                <EventPage />
            </>,
        availability: [ur.admin, ur.lotus, ur.privado, ur.petrobras, ur.bravante, ur.wsut, ur.starnav, ur.cbo, ur.ambipar, ur.asso]
    },
    {
        path: "/status",
        ele:
            <>
                <ResponsiveAppBar />
                <StatusPage />
            </>,
        availability: [ur.admin, ur.lotus, ur.privado, ur.petrobras, ur.bravante, ur.wsut, ur.starnav, ur.cbo, ur.ambipar, ur.asso]
    },
    {
        path: "/vessels",
        ele:
            <>
                <ResponsiveAppBar />
                <VesselsPage />
            </>,
        availability: [ur.admin, ur.lotus, ur.privado, ur.petrobras, ur.bravante, ur.wsut, ur.starnav, ur.cbo, ur.ambipar, ur.asso]
    }
]
